
import { defineComponent } from "vue";
import Header from "@/components/header.vue";
import Form from "@/components/form.vue";
import Footer from "@/components/footer.vue";
import Card from "@/components/review-card.vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    Form,
    Footer,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Card,
  },

  data() {
    return {
      reviews: [
        {
          name: "Allie",
          review: `You are so incredible Yana!! Would recommend you a million times. You are a master at your craft and the world is so lucky to have you making brides feel so beautiful on their special days!!`,
        },
        {
          name: "Rufena",
          review: `Oh my goodness my hair was beautiful thank you so much! The compliments did not end😅 you are so talented in your work❤️`,
        },
        {
          name: "Milana",
          review: `You did such a good job!!! I got so many compliments on my hair! And it stayed curled nicely`,
        },
        {
          name: "Antonina",
          review: `They held up wonderfully for everyone! We have all loved our hair. Thank you SO much!`,
        },
        {
          name: "Julia",
          review: `Keep on getting compliments all day today. You did an amazing job`,
        },
        {
          name: "Nadiya",
          review: `Thank you very much for the hair and makeup you created for my wedding! I looked irresistible. The hairstyle lasted all evening, and the makeup looked very natural, just as I wanted. The groom was delighted. Thank you very much again for my image!`,
        },
      ],
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        900: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1300: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
});
