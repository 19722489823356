import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { MotionPlugin } from '@vueuse/motion'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDzuK_psfyhyWndL3srE64DlQ1VEsFq6iM",
    authDomain: "berezh-studio.firebaseapp.com",
    projectId: "berezh-studio",
    storageBucket: "berezh-studio.appspot.com",
    messagingSenderId: "719516583090",
    appId: "1:719516583090:web:0b20d7153bfc5112bd35ab",
    measurementId: "G-3EXSNFTV1J"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


createApp(App).use(store).use(router).use(MotionPlugin).mount("#app");

export { app, analytics };
