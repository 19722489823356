
import { defineComponent } from "vue";

export default defineComponent({
  name: "formComponent",
  data() {
    return {
      showAdditionalQuestions: false,
    };
  },
});
