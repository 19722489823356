
import { defineComponent } from "vue";

export default defineComponent({
  name: "footerComponent",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },

});
