
import { defineComponent } from "vue";
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Form from "@/components/form.vue";
export default defineComponent({
  name: "ServicesView",
  components: {
    Header,
    Footer,
    Form,
  },
});
