<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap");
#app {
  font-family: "Kulim Park", sans-serif;
}

//disable forced dark mode
@media (prefers-color-scheme: light) {
  body {
    background-color: #fff;
    color: #000;
  }
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  --vc-pgn-active-color: #955f6e;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
}

.carousel__pagination {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 4rem !important;
}


.button {
  padding: 18px 28px;
  color: #fff;
  font-family: Kulim Park;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 15px;
  background: #955f6e;
  border: 1px solid #955f6e;
  transition: all 0.3s;

  &:hover {
    color: #955f6e;
    background: #fff;
  }
}


@media (max-width: 1000px) {
  .container {
    padding: 0 20px;
    margin-top: 10rem;
  }
}
</style>
